* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}
html {
    font-family: sans-serif;
    height: 100%;
}
a {
    user-select: none;
}
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    height: 100%;
}
:root {
    --font-family: "Commissioner", sans-serif;
    --second-family: "Nunito Sans", sans-serif;
    --third-family: "Noto Sans", sans-serif;
    --font3: "Georgia", sans-serif;
    --font4: "Inter", sans-serif;
    --font5: "Rubik Mono One", sans-serif;
    --font6: "Arial", sans-serif;

    --title_size: 130px;
    --subtitle_size: 25px;
    --ticker_size: 90px;
    --email_size: 30px;
    --info_text: 20px;
    --card_info_text: 20px;
    --footer_title_size: 155px;
    --footer_title_size_mob: 30vw;
    --footer_subtitle_size_mob: 4vw;

    --max_width: 1440px;
    --container: 90%;

    --header_background_color: #232323;
    --main_background_color: #14191c;
    --slogan_background_color: #dedfdf;
    --portfolio_background_color: #000;
    --about_background_color: #ffffff;
    --review_background_color: #3b3636;
    --contact_background_color: #ffffff;
    --footer_background_color: #14191c;
}

@media (max-width: 1518px) {
    :root {
        --title_size: 8.5vw;
    }
}
@media (max-width: 1068px) {
    :root {
        --footer_title_size: 15vw;
        --email_size: 3vw;
        --info_text: 15px;
        --card_info_text: 15px;
        --ticker_size: 7vw;
    }
}
@media (max-width: 900px) {
    :root {
        --subtitle_size: 20px;
    }
}
@media (max-width: 500px) {
    :root {
        --footer_subtitle_size_mob: 18px;
        --subtitle_size: 14px;
    }
}
