.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--footer_background_color);
}
.container {
    width: var(--container);
    max-width: var(--max_width);
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 60px 0px 20px 0;
}
.footer_desktop {
    width: 100%;
}
h1 {
    width: 100%;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--footer_title_size);
    line-height: 104%;
    color: #ffffe5;
    text-transform: uppercase;
}
.subContentBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subContentBlock h1 {
    width: max-content;
}
.contactBlock {
    width: max-content;
    height: max-content;
}
.contacts {
    display: flex;
    margin-top: 20px;
    gap: 10px;
}
.contacts p {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: var(--info_text);
    line-height: 120%;
    color: #fff;
}
.contactBlock a {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--email_size);
    line-height: 120%;
    color: #fff;
}
.credits {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
}
.credits p {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--info_text);
    line-height: 120%;
    color: #ca2c52;
}
/* Mobile vers */
.footer_mob {
    width: 100%;
    display: none;
}
.infoContainer_mob {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 30px;
}
.title_mob {
    text-align: center;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--footer_title_size_mob);
    color: #f4f4f4;
}
.subtitleContainer_mob {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.subtitleContainer_mob h1 {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--footer_subtitle_size_mob);
    color: #fff;
    text-align: end;
}
.subtitle_mob {
    color: #c11616 !important;
    text-decoration: underline white;
}
.sloganContainer_mob {
    width: 100%;
    font-family: var(--second-family);
    font-weight: 300;
    font-size: 16px;
    color: #d9d9d9;
}
.mail {
    cursor: pointer;
    user-select: none;
}
.contactInfoContainer_mob {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-family: var(--second-family);
    font-weight: 200;
    font-size: 20px;
    line-height: 120%;
    color: #bfb9ba;
}
.inputContainer {
    width: 100%;
    height: max-content;
    position: relative;
}
.inputContainer input {
    width: 100%;
    border-radius: 7px;
    height: 35px;
    font-size: 15px;
    padding: 20px 10px;
}
.inputContainer input:focus {
    outline: none;
}
.contactSubtitle_mob {
    margin-top: 20px;
    user-select: none;
}
.contactContainer_mob {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.contactContainer_mob div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.contactContainer_mob a {
    cursor: pointer;
    user-select: none;
    color: #fff;
}
@media (max-width: 1068px) {
    .container {
        padding: 20px 0px 60px 0;
    }
    .footer_desktop {
        display: none;
    }
    .footer_mob {
        display: block;
        padding: 0;
        margin: 0;
    }
    .credits p {
        color: #fff;
        font-weight: 100;
    }
}
