.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--review_background_color);
}
.container {
    width: var(--container);
    max-width: var(--max_width);
    display: flex;
    flex-direction: column;
    padding: 60px 0px 80px 0;
}
.container h1 {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--title_size);
    line-height: 104%;
    color: #fff;
}
.subtitle {
    text-align: center;
}
.slaiderContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 30px;
    margin-top: 70px;
}
.navigateSlaider {
    width: max-content;
    height: max-content;
    display: flex;
    gap: 15px;
}
.slaider {
    width: 100%;
    height: 350px;
    gap: 50px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
}
.shadowBlock {
    width: calc(100% + 10px);
    height: 200%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    -webkit-box-shadow: inset 0px 0px 60px 80px var(--review_background_color);
    -moz-box-shadow: inset 0px 0px 60px 80px var(--review_background_color);
    box-shadow: inset 0px 0px 60px 80px var(--review_background_color);
}
.infoContainer {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.reviews_text {
    width: 100%;
    text-align: start;
    padding-left: 40px;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--card_info_text);
    line-height: 104%;
    color: #fff;
}
.personContainer {
    margin-top: 20px;
    width: max-content;
    display: flex;
    justify-content: start;
    gap: 20px;
}
.personNameContainer {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.personNameContainer p {
    padding-left: 0px;
    width: max-content;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--card_info_text);
    line-height: 104%;
    color: #fff;
}
@media (max-width: 1033px) {
    .container {
        padding-bottom: 0px;
    }
    .slaiderContainer {
        margin-top: 8vw;
    }
    .slaider {
        height: 570px;
        margin-bottom: 100px;
    }

    .infoContainer {
        width: 100%;
    }
    .reviews_text {
        width: 100%;
        padding-left: 0px;
    }
    .personContainer {
        gap: 10px;
        margin-top: 20px;
    }
    .personNameContainer p {
        padding: 0;
    }
}
@media (max-width: 533px) {
    .shadowBlock {
        display: none;
    }
}
