.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--portfolio_background_color);
}
.container {
    width: var(--container);
    max-width: var(--max_width);
    display: flex;
    flex-direction: column;
    height: max-content;
    gap: 40px;
    position: relative;
    padding-top: 80px;
    padding-bottom: 100px;
}
.sectionAnim{
    width: 100%;
    height: max-content;
}
.titleContainer {
    width: 100%;
    height: max-content;
    position: sticky;
    top: 30px;
    left: 0;
}
.viewContainer {
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.titleContainer h1 {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--title_size);
    line-height: 104%;
    color: #fff;
}
.imageContainer {
    width: 100%;
    height: max-content;
    margin-top: 70px;
    position: relative;
    display: flex;
    flex-direction: column;
}
/*  */
.animTextContainer {
    width: 100%;
    margin: auto;
}
.items_wrap {
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 30px;
}
.items_wrap:before,
.items_wrap:after {
    content: "";
    height: 100%;
    top: 0;
    width: 10%;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}
.items {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    min-width: 100%;
}
.item {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px red;
    font-style: italic;
    flex: 0 0 auto;
    width: max-content;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    transition: all 0.1s ease-in-out;

    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--ticker_size);
    line-height: 104%;
}
.circle {
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
}

.marquee {
    animation: scroll 30s linear infinite;
}
.reverce {
    animation-direction: reverse;
}

.portfolio_image_1 {
    width: 600px;
    height: 350px;
}
.portfolio_image_2 {
    width: 520px;
    height: 555px;
}
.portfolio_image_3 {
    width: 423px;
    height: 282px;
}
.portfolio_image_4 {
    width: 423px;
    height: 282px;
}
.portfolio_image_5 {
    width: 589px;
    height: 339px;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 20px));
    }
}

@media (max-width: 1090px) {
    .circle {
        width: 15px;
        height: 15px;
    }
    .items_wrap {
        gap: 15px;
    }
    .items {
        gap: 15px;
    }
    .item {
        font-size: var(--ticker_size);
        -webkit-text-stroke: 1px red;
    }

    .portfolio_image_1 {
        width: 50vw;
        height: 29vw;
    }
    .portfolio_image_2 {
        width: 50vw;
        height: 55vw;
    }
    .portfolio_image_3 {
        width: 45vw;
        height: 28vw;
    }
    .portfolio_image_4 {
        width: 43vw;
        height: 30vw;
    }
    .portfolio_image_5 {
        width: 55vw;
        height: 33vw;
    }
    
}
@media (max-width: 500px) {
    .circle {
        width: 10px;
        height: 10px;
    }
}
