.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--contact_background_color);
}
.container {
    width: var(--container);
    max-width: var(--max_width);
    display: flex;
    flex-direction: column;
    padding: 60px 0px 80px 0;
}
.container h1 {
    text-align: end;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--title_size);
    line-height: 104%;
    color: #000;
}
.subtitle {
    width: 100%;
    text-align: end;
    /* color: #ca2c52 !important ; */
    color: #c11616 !important ;
}
.contentContainer {
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}
.contactInfo {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.paragContainer {
    display: flex;
    flex-direction: column;
    width: max-content;
}
.contactInfo a {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--email_size);
    line-height: 120%;
    color: #000;
}
.paragContainer p {
    width: max-content;
    font-family: var(--second-family);
    font-weight: 600;
    font-size: var(--info_text);
    line-height: 120%;
    color: #000;
    flex-shrink: 0;
}
.iconContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 50px;
}

.buttonWrite {
    width: 272px;
    height: 272px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000;
    flex-shrink: 0;
    user-select: none;
    cursor: pointer;

    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--email_size);
    line-height: 120%;
    /* color: #ca2c52; */
    color: #c11616;
    transition: all 0.3s;
}
.buttonWrite:hover {
    /* background-color: #ca2c52; */
    background-color: #c11616;
    color: #000000;
}
@media (max-width: 1068px) {
    .wrapper {
        display: none;
    }
    .buttonWrite {
        width: 30vw;
        height: 30vw;
    }
    .container {
        padding: 6vw 0px 7vw 0;
    }
    .iconContainer {
        margin-top: 3vw;
    }
    .contentContainer {
        margin-top: 5vw;
    }
}
