.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header_background_color);
    position: absolute;
    top: 0;
    z-index: 10;
}
.container {
    width: var(--container);
    max-width: var(--max_width);
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    background-image: url(../../assets/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 10;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.navbar {
    width: 70%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.navbar a {
    font-family: var(--font4);
    font-weight: 400;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}
.burgerButtonContainer {
    width: max-content;
    height: max-content;
    min-width: 60px;
    min-height: 60px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}
.burger {
    width: max-content;
    height: max-content;
    gap: 5px;
    cursor: pointer;
    display: none;
    z-index: 10;
    /* -webkit-tap-highlight-color: transparent; */

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.burgerIcon {
    width: 30px;
    height: 20px;
    flex-shrink: 0;
    background-image: url(../../assets/burgerIcon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.burger p {
    font-family: var(--font4);
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Burger */
.burgerContainer {
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s;
    z-index: 8;
}
.navbarBurger {
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: hidden;
}
.navbarBurger a {
    width: max-content;
    flex-shrink: 0;
    text-align: center;
    font-family: var(--font4);
    font-weight: 400;
    font-size: 30px;
    text-transform: uppercase;
    color: rgb(173, 69, 69);
    cursor: pointer;
    text-decoration: none;

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

}
@media (max-width: 900px) {
    .wrapper {
        position: fixed;
    }
    .burgerContainer {
        display: flex;
    }
    .container {
        height: 65px;
    }
    .logo {
        width: 45px;
        height: 45px;
    }
    .navbar {
        display: none;
    }
    .burger {
        display: flex;
    }
}
