.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--slogan_background_color);
}
.container {
    width: var(--container);
    max-width: var(--max_width);
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px 60px 0;
}
.container h1 {
    width: 100%;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: var(--title_size);
    line-height: 104%;
    color: #000;
}
.centerParag {
    text-align: center;
}
.subtitle {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 50px;
    margin-top: 30px;
}
.subtitle p {
    font-family: var(--second-family);
    font-weight: 600;
    font-size: var(--subtitle_size);
    line-height: 120%;
    color: #1b2933;
}
.content {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media (max-width: 1068px) {
    .container {
        padding: 60px 0px 80px 0;
    }
    .subtitle {
        margin-top: 14vw;
        flex-direction: column;
        gap:5vw;
    }
    .content {
        max-width: 100%;
        gap: 4vw;
    }
}
